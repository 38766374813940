import { defineMessages } from 'react-intl';
export default defineMessages({
  frameLimitModalTitle: {
    defaultMessage: 'Frame limit reached',
    id: 'frameLimitModal.title'
  },
  frameLimitModalBody: {
    defaultMessage:
      'Oh! Youve reached the limit of 6 frames per order. But dont worry, you can still add other items to your cart. If you want to order more frames, our Customer Service team is here to help.',
    id: 'frameLimitModal.body'
  },
  frameLimitModalPrimaryButton: {
    defaultMessage: 'Go to cart',
    id: 'frameLimitModal.primaryButton'
  },
  frameLimitModalSecondaryButton: {
    defaultMessage: 'Contact us',
    id: 'frameLimitModal.contactUs'
  }
});
