import React, { useContext, useState } from 'react';
import { Modal, Typography, grid, Button, breakpointRules } from '@aceandtate/ds';
import Link from 'next/link';
import { paths } from 'paths';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import styled from 'styled-components';
import { ServicesContext } from 'services/context';
import { Cart } from 'types/solidus';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

type UseFrameLimitModalReturn = {
  FrameLimitModal: React.FC;
  checkFrameLimit: (cart: Cart, productType: string) => boolean;
  checkFrameLimitWithOrder: (cart: Cart, order: Cart) => boolean;
};

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${grid[12]};
  @media ${breakpointRules.tablet} {
    flex-direction: row;
  }
`;

/**
 * Custom React hook to show the frame limit modal. You must call the checkFrameLimit before adding a frame to the cart,
 * if the limit and conditions are reached the modal will be shown. Note: This does not stop the add frame process, you should handle that based on the return value.
 *
 * @returns {UseFrameLimitModalReturn} An object containing the FrameLimitModal component and the checkFrameLimit function.
 *
 * @example
 * const { FrameLimitModal, checkFrameLimit } = useFrameLimitModal();
 */
const useFrameLimitModal = (): UseFrameLimitModalReturn => {
  const [isFrameLimitModalOpen, setIsFrameLimitModalOpen] = useState(false);
  const pathname = usePathname();
  const { webStore } = useContext(ServicesContext);

  const checkFrameLimit = (cart, productType) => {
    const frameNumber = cart?.regular_lines?.filter(line => line.product_type === 'frame').length || 0;
    if (frameNumber >= 6 && webStore.country.iso === 'DE' && productType === 'frame') {
      setIsFrameLimitModalOpen(true);
      return true;
    }
    return false;
  };

  const checkFrameLimitWithOrder = (cart, order: Cart) => {
    const frameNumber = cart?.regular_lines?.filter(line => line.product_type === 'frame').length || 0;
    const orderFrameNumber = order?.regular_lines?.filter(line => line.product_type === 'frame').length || 0;
    if (frameNumber + orderFrameNumber >= 6 && webStore.country.iso === 'DE') {
      setIsFrameLimitModalOpen(true);
      return true;
    }
    return false;
  };

  const onClose = () => {
    setIsFrameLimitModalOpen(false);
  };

  const FrameLimitModal = () => (
    <Modal
      image={
        <Image
          src='/static/images/generic-glasses/multiple-glasses.jpg'
          sizes={`${breakpointRules.laptop} 50vw, 100vw`}
          alt='Multiple glasses on a table'
          fill
          objectFit='cover'
        />
      }
      open={isFrameLimitModalOpen}
      width='750px'
      onClose={onClose}
      content={
        <>
          <Typography variant='h3' as='div' gutterBottom>
            <FormattedMessage {...messages.frameLimitModalTitle} />
          </Typography>
          <Typography gutterBottom={24}>
            <FormattedMessage {...messages.frameLimitModalBody} />
          </Typography>
          <StyledButtonContainer>
            {pathname === paths.cart ? (
              <Button
                as='span'
                onClick={() => {
                  setIsFrameLimitModalOpen(false);
                }}
              >
                <FormattedMessage {...messages.frameLimitModalPrimaryButton} />
              </Button>
            ) : (
              <Link href={paths.cart}>
                <Button as='span' fullWidth>
                  <FormattedMessage {...messages.frameLimitModalPrimaryButton} />
                </Button>
              </Link>
            )}
            <Link href={paths.contact}>
              <Button variant='outlined' as='span'>
                <FormattedMessage {...messages.frameLimitModalSecondaryButton} />
              </Button>
            </Link>
          </StyledButtonContainer>
        </>
      }
    />
  );

  return { FrameLimitModal, checkFrameLimit, checkFrameLimitWithOrder };
};

export default useFrameLimitModal;
